@import '../../styles/config.scss';

.about-container {
	.about-wrapper{
		display: grid;
		grid-template-columns: 1fr 1fr;
		
		@media screen and (max-width: $tabletSize){
			grid-template-columns: 1fr;
		}

		.about-info {
			padding: 12rem;
			box-sizing: border-box;

			@media screen and (max-width: $tabletSize){
				padding: 4rem;
			}

			h1 {
				font-size: $h1Size;
				font-weight: 900;
				font-style: italic;
				margin-bottom: 3rem;
			}

			p {
				font-size: 1.3rem;
				line-height: 1.7rem;
				font-weight: 300;
				
			}
		}
		
		.about-image-container {
			padding: 3rem;
			box-sizing: border-box;
	
			@media screen and (max-width: $tabletSize){
				width: 100%;
				height: 20rem;
			}

			@media screen and (max-width: $mobileSize){
				width: 100%;
				padding: 0rem;
			}

			.about-image {
				background-image: url('../../images/VanDouble1.jpg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				width: 100%;
				height: 100%;
			}
		}
	}

}